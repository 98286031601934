import './Button.scss';


function Button(props) {

    const callBack = () => {
        props.callBack ? props.callBack() : console.log(0)
    }

    return <button onClick={callBack} className="button" >{props.children}</button>;
    
}


export default Button;