import {
    SET_HISTORY_PAGE,
    SET_HISTORY_ORDERS,
    ORDER_HYSTORY_SAVE_ALL_ITEMS,
    ORDER__SAVEFINISH_REPORT
} from "./action";

const initialState = {
    historyPage: +localStorage.getItem('historyPage') || 1,
    historyOrders: [],

    allHystoryItems: +localStorage.allHystoryItems || 0,

    finalReport: localStorage.finalReport ? JSON.parse(localStorage.finalReport) : {},
}

const masterReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'AUTH__APP_CLEAR':

            return {
                ...state,
                historyPage: 1,
                historyOrders: [],

                allHystoryItems: 0,

                finalReport: {}
            }


        case ORDER__SAVEFINISH_REPORT:
            localStorage.setItem('finalReport', JSON.stringify(action.data));
            return {
                ...state,
                finalReport: action.data,
            }



        case SET_HISTORY_PAGE:
            localStorage.setItem('historyPage', action.historyPage);

            return {
                ...state,
                historyPage: action.historyPage,
            }


        case SET_HISTORY_ORDERS:


            return {
                ...state,
                historyOrders: action.historyOrders,
            }


        case ORDER_HYSTORY_SAVE_ALL_ITEMS:
            localStorage.setItem('allHystoryItems', action.allItems);

            return {
                ...state,
                allHystoryItems: +action.allItems,
            }


        default:
            return state;
    }
}
export default masterReducer