import './Input.scss';


function Input(props) {
    
    const callBack = (e) => {
        props.callBack({ id: e.target.id, value: e.target.value })
    }

    return (
        <input className="input" onChange={callBack} id={props.id} type={props.type} placeholder={props.placeholder} required />
    );
}


export default Input;