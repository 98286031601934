import { connect } from 'react-redux';

import './AlertWindow.scss';


function AlertWindow(props) {
    if (props.alertWindowStatus) {
        return <div className="alert_window_wrapper">
            <div className="alert_window" >
                {props.alertWindowMsg}
            </div>
        </div>
    } else {
        return '';
    }
}


const mapStateToProps = state => {
    return {
        alertWindowStatus: state.authReducer.alertWindowStatus,
        alertWindowMsg: state.authReducer.alertWindowMsg,
    }
}

const mapDispatchToProps = {}


export default connect(mapStateToProps, mapDispatchToProps)(AlertWindow)