import {
    ORDER__SAVE_TURN,
    ORDER__SAVE_PREVIEW_OREDER_DATA,
    ORDER__OPEN_TURN_ITEM_PREVIEW,

    ORDER__SAVE_PREVIEW_ORDER_ID,

    ORDER__SET_OPEN_ADD_PHONE,
    ORDER__SET_STATUS_ERROR_DISCRPTION_WINDOW,

    ORDER__SAVE_APPOINTMENT,
    ORDER__SAVE_CURRENT_ORDERS,

    ORDER__SET_OPEN_CUSTOMER_HISTORY,


    SET_CUSTOMER_HISTORY_PAGE,
    SET_CUSTOMER_HISTORY_ORDERS
} from "./action";
import {
    ORDER__OREDER_CLEAR
} from "../orderInProgress/action";


const initialState = {

    turn: [],
    appointment: [],

    openTurnItemPreview: +localStorage.openTurnItemPreview || 0,
    previewOrderData: localStorage.previewOrderData ? JSON.parse(localStorage.previewOrderData) : {},


    previewOrderId: +localStorage.previewOrderId || 0,

    openAddPhone: +localStorage.openAddPhone || 0,
    statusErrorDiscriptionWindow: +localStorage.statusErrorDiscriptionWindow || 0,

    openCustomerHistory: +localStorage.openCustomerHistory || 0,
    historyCustomerPage: +localStorage.getItem('historyCustomerPage') || 1,
    historyCustomerOrders: [],
    currentOrders: [],

    historyCustomerOrdersPerPage: 7

}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'AUTH__APP_CLEAR':

            return {
                ...state,
                turn: [],
                appointment: [],
                openTurnItemPreview: 0,
                previewOrderData: {},
                previewOrderId: 0,
                openAddPhone: 0,
                customerName: ''
            }

        case ORDER__OREDER_CLEAR:
            localStorage.setItem('openTurnItemPreview', 0);
            localStorage.setItem('previewOrderData', JSON.stringify({}));
            localStorage.setItem('previewOrderId', 0);
            localStorage.setItem('openAddPhone', 0);
            localStorage.setItem('customerName', 0);

            return {
                ...state,
                turn: [],
                appointment: [],
                openTurnItemPreview: 0,
                previewOrderData: {},
                previewOrderId: 0,
                openAddPhone: 0,
                customerName: ''
            }


        case ORDER__SAVE_CURRENT_ORDERS:
            return {
                ...state,
                currentOrders: action.data,
            }


        case ORDER__SAVE_APPOINTMENT:
            return {
                ...state,
                appointment: action.data,
            }


        case ORDER__SAVE_TURN:
            return {
                ...state,
                turn: action.data,
            }


        case ORDER__SAVE_PREVIEW_OREDER_DATA:
            localStorage.setItem('previewOrderData', JSON.stringify(action.data))
            return {
                ...state,
                previewOrderData: action.data,
            }


        case ORDER__OPEN_TURN_ITEM_PREVIEW:
            localStorage.setItem('openTurnItemPreview', action.data)
            return {
                ...state,
                openTurnItemPreview: action.data,
            }


        case ORDER__SET_OPEN_CUSTOMER_HISTORY:
            localStorage.setItem('openCustomerHistory', action.status)
            return {
                ...state,
                openCustomerHistory: action.status,
            }


        case ORDER__SAVE_PREVIEW_ORDER_ID:
            localStorage.setItem('previewOrderId', action.data)
            return {
                ...state,
                previewOrderId: +action.data,
            }


        case ORDER__SET_OPEN_ADD_PHONE:
            localStorage.setItem('openAddPhone', action.status)
            return {
                ...state,
                openAddPhone: +action.status,
            }


        case ORDER__SET_STATUS_ERROR_DISCRPTION_WINDOW:
            localStorage.setItem('statusErrorDiscriptionWindow', action.status)
            return {
                ...state,
                statusErrorDiscriptionWindow: +action.status,
            }


        case SET_CUSTOMER_HISTORY_PAGE:
            localStorage.setItem('historyCustomerPage', action.data);
            return {
                ...state,
                historyCustomerPage: action.data,
            }


        case SET_CUSTOMER_HISTORY_ORDERS:
            return {
                ...state,
                historyCustomerOrders: action.data,
            }

        default:
            return state;
    }
}
export default orderReducer