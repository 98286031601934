import { useState } from "react";
import './ErrorDescriptionWindow.scss';
import { connect } from 'react-redux';

import { setStatusErrorDiscriptionWindow } from '../../redux/order/action';
import { setOrderStatusError, setOrderStatus } from '../../redux/orderInProgress/action';

import { withRouter } from 'react-router-dom';


function ErrorDescriptionWindow(props) {

    const [description, setDescription] = useState('')

    const addErrorDescription = (event) => {
        event.preventDefault();

        if (description.length > 5) {
            console.log(description)
            props.setOrderStatusError(description)
            .then(()=> {
                return props.setOrderStatus(6)
            })
            .then(() => {
                props.history.push('/order_completed')
            })
        }
    }

    const onChangeDescription = (event) => {
        setDescription(event.target.value)
    }

    const wrapperCloseWindow = (event) => {
        if (event.target.className === 'error_description_window_wrapper') {
            props.setStatusErrorDiscriptionWindow(0)
        }
    }

    if (!props.statusErrorDiscriptionWindow) {
        return ''
    }

    return (
        <div className="error_description_window_wrapper" onClick={wrapperCloseWindow}>
            <form id="error_description_window" onSubmit={addErrorDescription}>

                <h3>Kоротко опишите проблему и нажмите далее для отмены заказа</h3>

                <textarea onChange={onChangeDescription} value={description} type='text' class="error_textarea" />

                <div className="bnt_block">
                    <button onClick={() => props.setStatusErrorDiscriptionWindow(0)} className="to_back">{"<"} НАЗАД</button>
                    <button type='submit' style={description.length > 5 ? { color: '#b30101' } : { color: '#c3c3c3' }} className="next">ДАЛЕЕ {">"}</button>
                </div>

            </form>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        statusErrorDiscriptionWindow: state.orderReducer.statusErrorDiscriptionWindow
    }
}

const mapDispatchToProps = {
    setStatusErrorDiscriptionWindow,
    setOrderStatusError,
    setOrderStatus
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorDescriptionWindow))