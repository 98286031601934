import {
    AUTH__SAVE_TOKENS,
    AUTH__SAVE_MASTER_DATA_FROM_TOKEN,
    AUTH__SET_WORKPLACE_DATA,
    AUTH__SAVE_SESSION_NUMBER,
    SET_ALERT_WINDOW,
    AUTH__SAVE_SERVICES_LIST,
    AUTH__LOGIN_LOADING,
    AUTH__SAVE_PAYMENT_TYPES_ACTIVE
} from "./action";

const initialState = {
    login: localStorage.login || false,
    accessToken: localStorage.accessToken || '',
    refreshToken: localStorage.refreshToken || '',
    expiresIn: +localStorage.expiresIn || '',


    masterId: localStorage.accessToken ? +JSON.parse(atob(localStorage.accessToken.split('.')[1])).masterData.id : null,
    masterSurname: localStorage.accessToken ? JSON.parse(atob(localStorage.accessToken.split('.')[1])).masterData.surname : '',
    masterName: localStorage.accessToken ? JSON.parse(atob(localStorage.accessToken.split('.')[1])).masterData.name : '',
    masterExperience: localStorage.accessToken ? JSON.parse(atob(localStorage.accessToken.split('.')[1])).masterData.experience : '',

    alertWindowStatus: false,
    alertWindowMsg: '',

    freshTokenPromise: '',


    workplaceNumber: +localStorage.workplaceNumber || '',
    workPointId: +localStorage.workPointId || '',
    workPoint: localStorage.workPoint || '',
    configurationWorkplace: (localStorage.configurationWorkplace && JSON.parse(localStorage.configurationWorkplace)) || '',
    companyName: localStorage.companyName || '',

    sessionNumber: localStorage.sessionNumber || null,

    servicesList: localStorage.servicesList ? JSON.parse(localStorage.servicesList) : [],

    paymentTypesActive: +localStorage.paymentTypesActive ? +localStorage.paymentTypesActive : 0,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'AUTH__APP_CLEAR':
            return initialState;


        case AUTH__LOGIN_LOADING:
            return {
                ...state,
                login: 'login'
            }

        case AUTH__SAVE_SERVICES_LIST:
            localStorage.setItem('servicesList', JSON.stringify(action.data));

            return {
                ...state,
                servicesList: action.data,
            }

            
            case AUTH__SAVE_PAYMENT_TYPES_ACTIVE:
                localStorage.setItem('paymentTypesActive', action.data);
    
                return {
                    ...state,
                    paymentTypesActive: action.data,
                }

        case 'REFRESHING_TOKEN':
            return {
                ...state,
                freshTokenPromise: action.freshTokenPromise
            }

        case 'DONE_REFRESHING_TOKEN':
            return {
                ...state,
                freshTokenPromise: ''
            }
        case AUTH__SAVE_TOKENS:
            localStorage.setItem('accessToken', action.accessToken);
            localStorage.setItem('refreshToken', action.refreshToken);
            localStorage.setItem('expiresIn', action.expiresIn);
            localStorage.setItem('login', true);

            return {
                ...state,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                expiresIn: action.expiresIn,
                login: true
            }


        case AUTH__SAVE_MASTER_DATA_FROM_TOKEN:

            return {
                ...state,
                masterId: action.masterId,
                masterSurname: action.masterSurname,
                masterName: action.masterName,
                masterExperience: action.experience,
            }

        case SET_ALERT_WINDOW:

            return {
                ...state,
                alertWindowStatus: action.status,
                alertWindowMsg: action.msg
            }


        case AUTH__SET_WORKPLACE_DATA:
            localStorage.setItem('workplaceNumber', action.workplaceNumber);
            localStorage.setItem('workPoint', action.workPoint);
            localStorage.setItem('configurationWorkplace', action.configuration);
            localStorage.setItem('workPointId', action.workPointId);

            return {
                ...state,
                workplaceNumber: action.workplaceNumber,
                workPointId: action.workPointId,
                workPoint: action.workPoint,
                configurationWorkplace: JSON.parse(action.configuration)
            }

      

        case AUTH__SAVE_SESSION_NUMBER:
            localStorage.setItem('sessionNumber', action.data);

            return {
                ...state,
                sessionNumber: action.data
            }


        default:
            return state;
    }
}
export default authReducer