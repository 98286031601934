export const SET__NOTIFICATION_WINDOW = 'SET__NOTIFICATION_WINDOW';
export function setNotificationWindow(type, message) {
    return (dispatch, getState) => {
        let key = Date.now();

        setTimeout(() => {
            dispatch(removeOneNotificationWindows(key));
        }, 10000);

        dispatch({
            type: SET__NOTIFICATION_WINDOW,
            data: {
                type,
                message,
                key,
                class: 'added'
            }
        })
    }
}


export function removeAllNotificationWindows() {
    return (dispatch, getState) => {
        let items = getState().appPartsReducer.notificationWindows;

        for (let i = 0; i < items.length; i++) {
            dispatch(removeOneNotificationWindows(items[i].key));
        }
    }
}


export const REMOVE__ONE_NOTIFICATION_WINDOW = 'REMOVE__ONE_NOTIFICATION_WINDOW';
export const REMOVE__ONE_NOTIFICATION_WINDOW_ADD_CLASS_ANIMATION = 'REMOVE__ONE_NOTIFICATION_WINDOW_ADD_CLASS_ANIMATION';
export function removeOneNotificationWindows(key) {
    return (dispatch, getState) => {

        setTimeout(() => {
            dispatch({
                type: REMOVE__ONE_NOTIFICATION_WINDOW,
                key
            });
        }, 1000);

        dispatch({
            type: REMOVE__ONE_NOTIFICATION_WINDOW_ADD_CLASS_ANIMATION,
            key
        })
    }
}