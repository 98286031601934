import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import './Workplace.scss';

import { checkWorkplace, logout } from '../../redux/auth/action';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';


function Workplace(props) {

    useEffect(() => {
        document.title = "Ввод номера рабочего места | TOTO";
    }, [])

    const [workplaceNumber, setWorkplaceNumber] = useState('');

    const getInputs = (item) => {
        if (item.id === 'workplaceNumber') {
            setWorkplaceNumber(item.value)
        }
    }

    const checkWorkplace = (event) => {
        event.preventDefault();

        if (workplaceNumber !== '') {
            props.checkWorkplace(workplaceNumber);
        }
    }


    if (props.workplaceNumber) {
        return <Redirect to="/start_check" />
    }

    return (
        <div className="login">
            <div className="login_block">
                <form onSubmit={checkWorkplace} action="">
                    <legend>Введите номер рабочего места:</legend>

                    <Input
                        callBack={getInputs}
                        id='workplaceNumber'
                        type='text'
                        placeholder='Номер рабочего места' />
                    <Button>Далее</Button>
                    <Button callBack={props.logout}>Выход </Button>

                </form>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        workplaceNumber: state.authReducer.workplaceNumber,
    }
}

const mapDispatchToProps = {
    checkWorkplace,
    logout,
}


export default connect(mapStateToProps, mapDispatchToProps)(Workplace)