import { useEffect } from 'react';
import './History.scss';

import { connect } from 'react-redux';
import { getHistoryPage, saveHistoryPage } from '../../redux/master/action';

import { Link } from "react-router-dom";


function History(props) {

    useEffect(() => {
        document.title = `История заказов | ТОТО`;
        props.getHistoryPage()
    }, [])

    const nextPage = () => {
        if (props.allHystoryItems - ((props.historyPage + 1) * 7) > -7) {
            props.saveHistoryPage(props.historyPage + 1)
            props.getHistoryPage()
        }
    }

    const backPage = () => {
        props.saveHistoryPage(props.historyPage - 1)
        props.getHistoryPage()
    }


    let item = 0;
    if (props.historyPage === 1) {
        item = props.allHystoryItems + 1;
    } else {
        item = props.allHystoryItems + 1 - ((props.historyPage - 1) * 7);
    }


    let list = props.historyOrders.map(value => {
        item--
        return (
            <tr style={(+value.status_id !== 3) ? { background: 'rgb(255 0 0 / 0.1)' } : {}}>
                <td>{item}</td>
                <td>{value.turn_num}</td>
                <td>{value.customer_phone}</td>
                <td dangerouslySetInnerHTML={{ __html: value.services }}></td>
                <td>{value.date_time}</td>
                <td>{value.sum}</td>
                <td>{value.status}</td>
            </tr>
        )
    })


    return (
        <div id="history">

            <h1>История заказов </h1>
            <div className="table_wrapp">
                <table>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Номер чека</th>
                            <th>Телефон</th>
                            <th>Услуги</th>
                            <th>Дата заказа</th>
                            <th>Сумма заказа</th>
                            <th>Статус заказа</th>
                        </tr>
                    </thead>
                    <tbody>

                        {list}

                    </tbody>
                </table>
            </div>

            <nav>
                <button onClick={backPage} className="to_back">{'<'} Предведущая</button>

                <Link to='/turn/live' className="back" >Назад к очереди</Link>

                <button onClick={nextPage} className="to_next">Следующая {'>'}</button>
            </nav>

        </div>
    );
}


const mapStateToProps = state => {
    return {
        historyOrders: state.masterReducer.historyOrders,
        historyPage: state.masterReducer.historyPage,
        allHystoryItems: state.masterReducer.allHystoryItems,
    }
}

const mapDispatchToProps = {
    getHistoryPage,
    saveHistoryPage
}


export default connect(mapStateToProps, mapDispatchToProps)(History)