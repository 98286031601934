import { useState, useEffect } from 'react';
import './OrderInProgress.scss';
import Cash from '../../assets/img/Cash.svg';
import CreditCard from '../../assets/img/CreditCard.svg';


import { connect } from 'react-redux';
import {
    getTurn, getPreviewOrderData,
    // savePreviewOrderId, 
    setCustomerScreenWorkDirection, setOpenAddPhone, setStatusErrorDiscriptionWindow, takeOrder
} from '../../redux/order/action';
import { deleteOrderService, plusService, setOrderStatus } from '../../redux/orderInProgress/action';

import { withRouter } from "react-router-dom";

import Stopwatch from '../../components/Stopwatch/Stopwatch';

import { BASE_URL_AUDIO } from '../../assets/js/baseUrl';
import AddPhoneWindow from '../../components/AddPhoneWindow/AddPhoneWindow';
import { displayPhone } from '../../assets/js/functions';
import ErrorDescriptionWindow from '../../components/ErrorDescriptionWindow/ErrorDescriptionWindow';


function OrderInProgress(props) {

    useEffect(() => {
        document.title = "Выполнение заказа | TOTO";
    }, [])

    const [openOtherBtn, setOpenOtherBtn] = useState(false);
    const toOpenOtherBtn = () => {
        setOpenOtherBtn(!openOtherBtn)
    }

    const [openPlusOrderService, setOpenPlusOrderService] = useState(false);
    const toOpenPlusOrderService = () => {
        setOpenPlusOrderService(!openPlusOrderService)
    }

    const deleteOrderService = (event) => {
        props.deleteOrderService(event.target.dataset.orderServId, event.target.dataset.haveSaleBalance);
    }

    const [plusServices, setPlusServices] = useState({});
    const selectPlusService = (e) => {
        e.stopPropagation()

        let servId = +e.target.dataset.servId;
        let servData = {
            servId,
            servName: e.target.dataset.servName,
            servPrice: +e.target.dataset.servPrice,
            haveSaleBalance: e.target.dataset.haveSaleBalance
        }
        if (plusServices[servId]) {
            deleteServToState(servId);
        } else {
            addServToState(servId, servData);
        }
    }

    const addServToState = (servId, servData) => {
        let plusServicesNew = Object.assign({}, plusServices);
        plusServicesNew[servId] = servData;
        setPlusServices(plusServicesNew);
    }

    const deleteServToState = (servId) => {
        let plusServicesNew = Object.assign({}, plusServices);
        delete plusServicesNew[servId];
        setPlusServices(plusServicesNew);
    }

    const plusService = (event) => {
        event.preventDefault();
        if (Object.keys(plusServices).length === 0) {
            return
        }

        console.log(plusServices)
        props.plusService(plusServices)

        setPlusServices({})
        setOpenPlusOrderService(false)

        let inputs = document.querySelectorAll('.serv_input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
        }
    }

    const setOrderStatus = (event) => {
        props.setOrderStatus(event.target.dataset.status)
            .then(() => {
                props.history.push('/order_completed')
            });
    }

    const [paymentTypesWindowStatus, setPaymentTypesWindowStatus] = useState(false);
    const takePayment = (paymentType) => {
        console.log(paymentType)
        props.setOrderStatus(3, paymentType)
            .then(() => {
                props.history.push('/order_completed')
            });
    }

    const getPaymentType = () => {
        if (props.paymentTypesActive) {
            setPaymentTypesWindowStatus(true)
        } else {
            props.setOrderStatus(3, 1)
            .then(() => {
                props.history.push('/order_completed')
            });
        }
    }

    const [selectedCartoon, setSelectedCartoon] = useState(0);
    const selectCartoon = (event) => {
        let id = +event.target.value;
        setSelectedCartoon(id)
        props.setCustomerScreenWorkDirection(id)
    }

    const [audio] = useState(new Audio(BASE_URL_AUDIO + 'disinfect.mp3'));
    useEffect(() => {
        audio.play();
    }, []);

    return (
        <div id="order_in_progress">

            <div class="top_block">
                <div class="time"><Stopwatch /></div>
                <div class="customer_data">{props.previewOrderData.customer_name} <br /> <span> {+props.previewOrderData.customer_phone ? displayPhone(props.previewOrderData.customer_phone.split(''), '-') : 'Без телефона'}</span></div>
                <div class="turn_num">{props.previewOrderData.turn_num}</div>
            </div>

            <div class="order_services">
                {props.previewOrderData.services.map(value => {
                    return (
                        <div class='order_serv'>
                            <div class='name'>{value.name}</div>
                            <div class='price'>{value.price}</div>
                            {props.previewOrderData.services.length > 1 ? <button class="delete_order_serv" data-order-serv-id={value.id} data-have-sale-balance={value.have_sale_balance} onClick={deleteOrderService}>Удалить</button> : ''}
                        </div>
                    )
                })}
            </div>

            <div class="allsum_block">
                Итоговая сумма:<span>{props.previewOrderData.all_sum}</span>
            </div>

            <div class="btn_list">

                <div class="plus_order_service" onClick={toOpenPlusOrderService}>Добавить Услуги</div>
                <div class="order_finish" data-status='3' onClick={getPaymentType}>Принять отплату и завершить заказ</div>
                <div class="cartoon">
                    <div class="btncartoonhead">Управление мультфильмами</div>
                    {
                        [{ value: 3, age: '3 - 4' }, { value: 4, age: '5 - 6' }, { value: 5, age: '7 - 10' }].map(obj => {
                            return (
                                <label>
                                    <input
                                        type="radio"
                                        name='cartoon'
                                        onChange={selectCartoon}
                                        value={obj.value}
                                        checked={selectedCartoon === obj.value}
                                    />
                                    <div class="age">{obj.age}</div>
                                </label>
                            )
                        })
                    }
                </div>
                <div class="other" onClick={toOpenOtherBtn}>Другое {openOtherBtn ? '-' : '+'}</div>

                {openOtherBtn && (
                    <>
                        <div class="customer_go_away" data-status='4' onClick={setOrderStatus}>Клиент ушел</div>
                        <div class="no_money" data-status='5' onClick={setOrderStatus}>Клиент отказался от оплаты</div>
                        <div class="no_money" data-status='6' onClick={() => props.setStatusErrorDiscriptionWindow(1)}>Ошибка</div>
                        {!+props.previewOrderData.customer_phone && <div class="no_money" onClick={() => props.setOpenAddPhone(1)}>Добавить телефон</div>}
                    </>
                )}

            </div>


            {openPlusOrderService ? <form className="plus_order_service_window" style={openPlusOrderService ? {} : { display: 'none' }} onSubmit={plusService}>
                <h3>Выбор услуг</h3>
                <div class="serv_block">
                    {props.servicesList.map(value => {
                        let servicesInOrder = props.previewOrderData.services;
                        let disablet = false;
                        console.log( servicesInOrder)
                        for (let i = 0; i < servicesInOrder.length; i++) {
                            if (+servicesInOrder[i].service_name_id === +value.id){
                                disablet = true;
                            }
                            if(servicesInOrder[i].have_sale_balance === 'creative_haircut_balance' && value.serial_number == 13){
                                disablet = true;
                            }
                            if(servicesInOrder[i].name == 'Шейвер'  && value.have_sale_balance === 'creative_haircut_balance'){
                                disablet = true;
                            }
                        }

                        return (
                            <label>
                                <input
                                    type="checkbox"
                                    className='serv_input'
                                    onChange={selectPlusService}
                                    key={value.id}
                                    data-serv-id={value.id}
                                    data-serv-name={value.name}
                                    data-serv-price={value.price}
                                    data-have-sale-balance={value.have_sale_balance}

                                    checked={plusServices[value.id] ? true : false}
                                    disabled={disablet} />
                                <div className="serv">
                                    <div className="serv_name">{value.name}</div>
                                    <div className="serv_price">{value.price} р</div>
                                </div>
                            </label>
                        )
                    })}
                </div>

                <div class="btn_block_plus_order_service">
                    <button class="back" type='button' onClick={toOpenPlusOrderService}>НАЗАД</button>
                    <button type='submit' className='next' style={Object.keys(plusServices).length !== 0 ? { color: '#b30101' } : { color: '#c3c3c3' }}  >ДОБАВИТЬ</button>
                </div>
            </form> : ''}
            {+props.openAddPhone ? <div className="add_phone_wrapp">
                <div className="modal_window">
                    <AddPhoneWindow />
                </div>

            </div> : ''}

            {paymentTypesWindowStatus ? <div className="add_phone_wrapp">
                <div className="modal_window">
                    <div className="modal_window-payment-wrapp">
                        <h3>Закрывать заказ только после принятия денег</h3>

                        <div className="payment-type-button__wrapp">
                            <button onClick={() => takePayment(1)} className="payment-type-button">
                                <img src={Cash} alt="" srcset="" />
                                <h3>Наличными</h3>
                            </button>
                            <button onClick={() => takePayment(2)} className="payment-type-button">
                                <img src={CreditCard} alt="" srcset="" />
                                <h3>Картой</h3>
                            </button>
                        </div>

                    </div>
                </div>

            </div> : ''}

            <ErrorDescriptionWindow />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        sessionNumber: state.authReducer.sessionNumber,
        workplaceNumber: state.authReducer.workplaceNumber,

        turn: state.orderReducer.turn,
        openTurnItemPreview: state.orderReducer.openTurnItemPreview,

        previewOrderData: state.orderReducer.previewOrderData,
        customerName: state.orderReducer.customerName,

        servicesList: state.authReducer.servicesList,
        openAddPhone: state.orderReducer.openAddPhone,

        paymentTypesActive: state.authReducer.paymentTypesActive
    }
}

const mapDispatchToProps = {
    getTurn,
    getPreviewOrderData,
    deleteOrderService,
    plusService,
    setOrderStatus,

    setCustomerScreenWorkDirection,
    setOpenAddPhone,
    setStatusErrorDiscriptionWindow
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderInProgress))
