import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { setNotificationWindow } from '../../redux/appParts/action';

import './Login.scss';

import { saveTokens, saveDataFromToken } from '../../redux/auth/action';
import $ from 'jquery';

import { BASE_URL_AUTH } from '../../assets/js/baseUrl';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';


function Login(props) {

    useEffect(() => {
        document.title = "Вход мастер | TOTO";
    }, [])

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const getInputs = (item) => {
        if (item.id === 'login') {
            setLogin(item.value)
        }
        if (item.id === 'password') {
            setPassword(item.value)
        }
    }

    const toLogin = (event) => {
        event.preventDefault();

        if (login.length > 4 || password.length > 4) {
            $.ajax({
                type: "POST",
                async: false,
                url: BASE_URL_AUTH + '/login',
                dataType: "json",
                data: 'DATA=' + JSON.stringify({ login, password }),
                success: data => {
                    props.saveTokens(data);
                    props.saveDataFromToken(data.accessToken)
                    props.history.push('/workplace');
                    props.setNotificationWindow('info', `Добро пожаловать!`);
                },
                error: (error) => {
                    props.setNotificationWindow('error', `Неправильный логин или пароль! \n Попробуйте еще раз!`);
                    console.log(error);
                }
            });
        }
    }


    return (
        <div className="login">
            <div className="login_block">
                <form onSubmit={toLogin} action="">
                    <legend>Введите данные для входа:</legend>

                    <Input
                        callBack={getInputs}
                        id='login'
                        type='text'
                        placeholder='Логин' />
                    <Input
                        callBack={getInputs}
                        id='password'
                        type='password'
                        placeholder='Пароль' />
                    <Button>Войти</Button>
                    <footer>V 2.1.0</footer>
                </form>
               
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {
    saveTokens,
    saveDataFromToken,
    setNotificationWindow
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))