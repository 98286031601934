import {
    ORDER__SAVE_SATATUS_OF_ORDER_COMPLITED,
    ORDER_SAVE_GIFT_DATA,
    ORDER__OREDER_CLEAR
} from "./action";

const initialState = {


    statusOrderComplited: +localStorage.statusOrderComplited || 0,
    customerName: localStorage.customerName || '',

    onCartoon: +localStorage.onCartoon || 0,

    giftData: localStorage.giftData ? JSON.parse(localStorage.giftData) : {},
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case 'AUTH__APP_CLEAR':
    
        return{
            statusOrderComplited: 0,
            customerName: '',
            onCartoon: 0,
            giftData: {}


        }
        case ORDER__OREDER_CLEAR:
            localStorage.setItem('statusOrderComplited', 0);
            localStorage.setItem('customerName', '');
            localStorage.setItem('onCartoon', 0);
            localStorage.setItem('giftData', JSON.stringify({}));
      

        return{
            statusOrderComplited: 0,
            customerName: '',
            onCartoon: 0,
            giftData: {}


        }


        case ORDER__SAVE_SATATUS_OF_ORDER_COMPLITED:
            localStorage.setItem('statusOrderComplited', action.status)
            return {
                ...state,
                statusOrderComplited: +action.status,
            }


        case ORDER_SAVE_GIFT_DATA:
            localStorage.setItem('giftData', JSON.stringify(action.giftData))
            return {
                ...state,
                giftData: action.giftData,
            }



        default:
            return state;
    }
}
export default orderReducer