import './TurnItem.scss';


function TurnItem(props) {

    const callBack = (e) => {
        props.callBack(e.currentTarget.dataset.orderId)
    }

    return (
        <div className="turn_item" onClick={callBack} data-order-id={props.servNum}>
            <h2>Номер в очереди:</h2>
            <div class='turn_num'>{props.turnNum}</div>
            <div class='time' style={props.time > '00:03:00' ? { color: 'red' } : { color: '#000' }}>{props.time}</div>
        </div>
    );
}


export default TurnItem;