import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { BASE_URL_API } from 'assets/js/baseUrl';

const ResetBalansModal = ({ open, orderId, phone, balance, creative_haircut_balance, service, getOrderData }) => {
  const STATUSES = Object.freeze({
    CANSELED: 'CANSELED',
    CHANGED: 'CHANGED',
  });
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handler = status => {
    if(loading) return;
    setLoading(true);
    console.log(open, orderId, {
      status,
      orderId,
      phone,
      balance, 
      creative_haircut_balance,
      service,
    })

    axios.post(BASE_URL_API + '/reset-balanse', {
      status,
      orderId,
      phone,
      balance, 
      creative_haircut_balance,
      service,
    })
    .then(() => {
      getOrderData();
      toast.success('Выбор сохранен!');
      setShow(false);
    })
    .catch((err) => {
      console.log(err);
      toast.error('Ошибка переноса баланса, необходим ручной перенос!');
      setShow(false);
    })
    .finally(() => {
      setLoading(false);
    })
  };

  return (
    <Dialog open={open && show} aria-labelledby="form-dialog-title">
      <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold' }}>Перенос баланса обычных стрижек на стрижки женские</DialogTitle>
      <DialogContent style={{ paddingBottom: 24 }}>
        <DialogContentText style={{ textAlign: 'center' }}>
          Перенести баланс?
          (в случае если следующая обычная стрижка должна была быть скидочной - стоимость пересчитается)
        </DialogContentText>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => handler(STATUSES.CANSELED)}
            style={{ width: 300, height: 200, marginRight: 20, background: 'red', color: '#fff' }}
            loading={loading}
          >
            Отмена
          </Button>
          <Button
            onClick={() => handler(STATUSES.CHANGED)}
            style={{ width: 300, height: 200, background: 'green', color: '#fff' }}
            loading={loading}
          >
            Перенести баланс
          </Button>
        </div>
      </DialogContent>
    </Dialog >
  )
}

export default ResetBalansModal
