import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Switch, Route, BrowserRouter, NavLink } from "react-router-dom";

import './Turn.scss';

import { getTurn, getPreviewOrderData,
     //savePreviewOrderId,
      setCustomerScreenWorkDirection } from '../../redux/order/action';

import TurnItem from '../../components/TurnItem/TurnItem';
import TurnItemPreview from '../../components/TurnItemPreview/TurnItemPreview';
import TurnItemAddNewOrder from './components/TurnItemAddNewOrder';


function Turn(props) {

    useEffect(() => {
        document.title = "Очередь | TOTO";

        props.getTurn();

        let intervalId = setInterval(props.getTurn, 7000)

        return () => clearInterval(intervalId)
    }, [])


    const openTurnItemPreview = (orderId) => {
        // props.savePreviewOrderId(orderId);
        props.getPreviewOrderData(orderId);
    }

    const goToCurrentOrder = (orderId) => {
        // props.savePreviewOrderId(orderId);
        props.getPreviewOrderData(orderId, true)
        .then(() => {
            props.history.push('/order_in_progress')
        })
    }


    if (!props.workplaceNumber) {
        return (<Redirect to="/workplace" />)
    }
    if (!props.sessionNumber) {
        return <Redirect to='/start_check' />
    }

    let listTurn
    if (props.turn.length) {
        listTurn = props.turn.map((value) => <TurnItem callBack={openTurnItemPreview} servNum={value.id} turnNum={value.turn_num} time={value.date_time_in_turn} />)
    } else {
        listTurn = <div className='not_turn'>Очередь отсутствует</div>;
    }


    return (
        <div id="turn">

            <BrowserRouter basename='/turn'>

                <ul className="turn_top_tabs">
                    <li className="turn_top_tab"><NavLink exact={true} to='/live' activeClassName="selected_tab">Очередь</NavLink></li>
                    <li className="turn_top_tab"><NavLink to='/appointment' activeClassName="selected_tab">Запись </NavLink>{props.appointment.length !== 0 && <span>{props.appointment.length}</span>}</li>
                    <li className="turn_top_tab"><NavLink to='/currentOrders' activeClassName="selected_tab">Текущие  </NavLink>{props.currentOrders.length !== 0 && <span>{props.currentOrders.length}</span>}</li>
                </ul>

                <Route exact path="/live">
                    <div className="turn_items">
                        {listTurn}
                    </div>
                </Route>

                <Route path="/appointment">
                    <div className="lo">В разработке</div>
                </Route>

                <Route path="/currentOrders">
                    {props.currentOrders.length !== 0 ? props.currentOrders.map((value) => <TurnItem callBack={goToCurrentOrder } servNum={value.id} turnNum={value.turn_num} time={value.date_time_in_turn} />) : <div className='not_turn'>Заказы отсутствуют</div>}
                   
                </Route>

            </BrowserRouter >


            <div className="btn_turn">
                <Link to='/history'>История</Link>
                <Link to='/finish_check'>Завершить рабочую сессию</Link>
            </div>

            {props.openTurnItemPreview && props.previewOrderData && props.previewOrderData.customer_phone !== null ? <TurnItemPreview /> : ''}
            {props.openTurnItemPreview && props.previewOrderData && props.previewOrderData.customer_phone === null ? <TurnItemAddNewOrder /> : ''}
        </div>
    );
}


const mapStateToProps = state => {
    return {
        sessionNumber: state.authReducer.sessionNumber,
        workplaceNumber: state.authReducer.workplaceNumber,

        turn: state.orderReducer.turn,
        appointment: state.orderReducer.appointment,
        currentOrders: state.orderReducer.currentOrders,

        openTurnItemPreview: state.orderReducer.openTurnItemPreview,
        previewOrderData: state.orderReducer.previewOrderData,
    }
}

const mapDispatchToProps = {
    getTurn,
    getPreviewOrderData,
    // savePreviewOrderId,
    setCustomerScreenWorkDirection,
}

export default connect(mapStateToProps, mapDispatchToProps)(Turn)