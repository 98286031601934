import axios from 'axios';
import { setNotificationWindow } from '../appParts/action';
import { save, errorHandler } from '../../assets/js/functions';
import { BASE_URL_API } from '../../assets/js/baseUrl';


export const AUTH__LOGIN_LOADING = 'AUTH__LOGIN_LOADING';
export function loginLoading(){
    return {
        type: AUTH__LOGIN_LOADING
    }
}


export const SET_ALERT_WINDOW = 'SET_ALERT_WINDOW';
export function setAlertWindow(status, msg) {
    return {
        type: SET_ALERT_WINDOW,
        status,
        msg
    }
}


export const AUTH__SAVE_TOKENS = 'AUTH__SAVE_TOKENS';
export function saveTokens(tokens) {
    console.log(tokens)
    return {
        type: AUTH__SAVE_TOKENS,
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        expiresIn: tokens.expiresIn

    }
}


export const AUTH__SAVE_MASTER_DATA_FROM_TOKEN = 'AUTH__SAVE_MASTER_DATA_FROM_TOKEN';
export function saveDataFromToken(accessToken) {
    let accessTokenData = JSON.parse(atob(accessToken.split('.')[1]));
    return {
        type: AUTH__SAVE_MASTER_DATA_FROM_TOKEN,
        masterId: +accessTokenData.masterData.id,
        masterSurname: accessTokenData.masterData.surname,
        masterName: accessTokenData.masterData.name,
        experience: accessTokenData.masterData.experience,

    }
}


export const AUTH__SAVE_SERVICES_LIST = 'AUTH__SAVE_SERVICES_LIST';
export const AUTH__SAVE_PAYMENT_TYPES_ACTIVE = 'AUTH__SAVE_PAYMENT_TYPES_ACTIVE';
export function checkWorkplace(workplaceNumber) {
    return async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: BASE_URL_API + '/check_workplace',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                workplaceNumber
            }
        })
            .then(({data}) => {
                console.log(data)
                dispatch(setWorkplaceData(data.workplaceData, workplaceNumber));
                dispatch(save(AUTH__SAVE_SERVICES_LIST, data.servicesList));
                dispatch(save(AUTH__SAVE_PAYMENT_TYPES_ACTIVE, data.paymentTypesActive));
                
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Нет доступа к рабочему месту!')
            });
    }
}


export const AUTH__SET_WORKPLACE_DATA = 'AUTH__SET_WORKPLACE_DATA';
export function setWorkplaceData(data, workplaceNumber) {
    console.log(data);
    return {
        type: AUTH__SET_WORKPLACE_DATA,
        workplaceNumber,
        workPointId: data.work_point_id,
        workPoint: data.work_point,
        configuration: data.configuration
    }
}


export const AUTH__SAVE_SESSION_NUMBER = 'AUTH__SAVE_SESSION_NUMBER';
export function checkStartSession() {
    return async (dispatch, getState) => {
        await axios({
            method: 'get',
            url: BASE_URL_API + '/session',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            params: {
                workplaceNumber: getState().authReducer.workplaceNumber
            }
        })
            .then(({status, data}) => {
                console.log(data)
                if (status === 200) {
                    dispatch(save(AUTH__SAVE_SESSION_NUMBER, data.id));
                }
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка проверки начатой сессии!')
            });
    }
}


export function startSession(timeForStart) {
    return async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: BASE_URL_API + '/session/start',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                workPoint: getState().authReducer.workPoint,
                workplaceNumber: getState().authReducer.workplaceNumber, 
                timeForStart
            }
        })
            .then(({data}) => {
                console.log(data)
                dispatch(save(AUTH__SAVE_SESSION_NUMBER, data));
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка начатия сессии!')
            });
    }     
}


export function logout() {
    console.log('logout')
    localStorage.clear();
    window.location.replace('/');
}