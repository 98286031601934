import './CheckListItem.scss';


function CheckListItem(props) {
    return (
        <div className="check_list_item">
            <label>
                <input type="checkbox" name="qwe" />
                <div className="one_item">
                    <div className="name">{props.children}</div>
                    <div className="box"></div>
                </div>
            </label>
        </div>
    );
}


export default CheckListItem;