import { useState, useEffect } from 'react';
import './CheckListFinish.scss';

import { connect } from 'react-redux';
import { setTimeForFinishSession } from '../../redux/master/action';

import { Link, withRouter } from "react-router-dom";
import CheckListItem from '../../components/CheckListItem/CheckListItem';
import { BASE_URL_AUDIO } from '../../assets/js/baseUrl';


function CheckListFinish(props) {

    useEffect(() => {
        document.title = "Финишный КЛН";
    }, [])

    const timeForFinish = +new Date();

    const items = [
        'Очистить рабочий шкаф и кресло',
        'Вытереть зеркало',
        'Пополнить подарки',
        'Очистить мусорное ведро',
        'Очистить пылесос',
        'Помыть пол',
    ];

    const toFinishReport = (e) => {
        e.preventDefault();

        if (document.querySelectorAll('input:checked').length === items.length) {
            props.setTimeForFinishSession(Math.round((+new Date - timeForFinish) / 1000))
            .then(()=>{
                props.history.push('/final_report')
            })
        }
    }

    const [audio] = useState(new Audio(BASE_URL_AUDIO + 'vacuum.mp3'));
    useEffect(() => {
        if(new Date().getDate() % 2 === 0){
            audio.play();
        }
    },[]);


    return (
        <div id="check_list_finish">

            <h1>Финишный контрольный список</h1>
            <form onSubmit={toFinishReport} action="">

                {items.map((item) => <CheckListItem>{item}</CheckListItem>)}

                <div className="btns">
                    <Link to='/turn/live'>{'< НАЗАД'} </Link>
                    <button type='submit'>{'ДАЛЕЕ >'}</button>
                </div>
            </form>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        accessToken: state.authReducer.accessToken,
        expiresIn: state.authReducer.expiresIn,
        workplaceNumber: state.authReducer.workplaceNumber,
        sessionNumber: state.authReducer.sessionNumber,
        workPoint: state.authReducer.workPoint
    }
}

const mapDispatchToProps = {
    setTimeForFinishSession
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckListFinish))//102