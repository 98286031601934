import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { appClear } from './redux/master/action';

//pages
import Login from './pages/Login/Login';
import CheckListStart from './pages/CheckListStart/CheckListStart';
import Turn from './pages/Turn/Turn';
import Workplace from './pages/Workplace/Workplace';
import OrderInProgress from './pages/OrderInProgress/OrderInProgress';
import OrderCompleted from './pages/OrderCompleted/OrderCompleted';
import History from './pages/History/History';
import CheckListFinish from './pages/CheckListFinish/CheckListFinish';
import FinalReport from './pages/FinalReport/FinalReport';
import AlertWindow from './components/AlertWindow/AlertWindow';
import NotificationWindow from './components/Windows/NotificationWindow/NotificationWindow';
import Exit from './pages/Exit/Exit';


function App(props) {

  if (!props.accessToken || (+Date.now() >= props.expiresIn * 1000 - 10000)) {
    localStorage.clear()
    props.appClear()

    return (
      <Router basename='/'>
        <Login />
        <NotificationWindow />
      </Router>
    )
  }

  return (
    <Router basename='/'>

      <Switch>
        <Route exact path="/">
          <Redirect to="/start_check" />
        </Route>
        <Route path="/workplace" component={Workplace} />
        <Route path="/start_check" component={CheckListStart} />
        <Route path="/turn" component={Turn} />
        <Route path="/order_in_progress" component={OrderInProgress} />
        <Route path="/order_completed" component={OrderCompleted} />
        <Route path="/history" component={History} />
        <Route path="/finish_check" component={CheckListFinish} />
        <Route path="/final_report" component={FinalReport} />
        <Route path="/exit" component={Exit} />
      </Switch>

      <NotificationWindow />
      <AlertWindow />
      <ToastContainer />

    </Router>

  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    expiresIn: state.authReducer.expiresIn
  }
}

const mapDispatchToProps = {
  appClear
}


export default connect(mapStateToProps, mapDispatchToProps)(App)