import axios from 'axios';
import { setNotificationWindow } from '../appParts/action';
import { save, errorHandler } from '../../assets/js/functions';


import $ from 'jquery';
import { getPreviewOrderData, setCustomerScreenWorkDirection, setStatusErrorDiscriptionWindow } from '../order/action';
import {  BASE_URL_API } from '../../assets/js/baseUrl';



const PATH__DELETE_ORDER_SERVICE = BASE_URL_API + '/order_in_progress/delete_order_service';
const PATH__PLUS_SERVICES = BASE_URL_API + '/order_in_progress/plus_services';

const PATH__SET_SMS_OPTION = BASE_URL_API + '/order_in_progress/set_sms_option';
const PATH__SET_ORDER_STATUS_FROM_TURN = BASE_URL_API + '/order_in_progress/set_order_status_from_turn';



export const ORDER__SAVE_SATATUS_OF_ORDER_COMPLITED = 'ORDER__SAVE_SATATUS_OF_ORDER_COMPLITED';
export const ORDER_SAVE_GIFT_DATA = 'ORDER_SAVE_GIFT_DATA';
export const ORDER__OREDER_CLEAR = 'ORDER__OREDER_CLEAR';


export function deleteOrderService(orderServiceId, haveSaleBalance) {
    return (dispatch, getState) => {
        let customerData = {
            phone: +getState().orderReducer.previewOrderData.customer_phone,
            balance: +getState().orderReducer.previewOrderData.customer.balance,
            creative_haircut_balance: +getState().orderReducer.previewOrderData.customer.creative_haircut_balance
        }
        if (customerData.phone && haveSaleBalance) {
            if (+customerData[haveSaleBalance] === 0 && getState().orderReducer.previewOrderData.services_sale.length) {
                customerData[haveSaleBalance] = 2;
            } else {
                customerData[haveSaleBalance]--
            }
        }

        let data = {
            accessToken: getState().authReducer.accessToken,
            customerData,
            orderServiceId
        };
        console.log(data)

        $.ajax({
            type: "POST",
            async: false,
            url: PATH__DELETE_ORDER_SERVICE,
            dataType: "json",
            data: 'DATA=' + JSON.stringify(data),
            success: e => {
                console.log(e);
                dispatch(getPreviewOrderData(null, true))

            },
            error: (error) => {
                console.log(error);
            }
        });
    }
}


export function plusService(services) {
    return (dispatch, getState) => {
        // let accessToken = getState().authReducer.accessToken;

        let userData = {
            phone: getState().orderReducer.previewOrderData.customer_phone,
            balance: +getState().orderReducer.previewOrderData.customer.balance,
            creative_haircut_balance: +getState().orderReducer.previewOrderData.customer.creative_haircut_balance
        }


        let congratWindow = 0;


        for (const key in services) {

            console.log(services[key].haveSaleBalance)
            if (services[key].haveSaleBalance) {

                console.log(services[key].haveSaleBalance)
                console.log(userData[services[key].haveSaleBalance])

                if (userData[services[key].haveSaleBalance] === 2) {
                    services[key].servPrice /= 2;
                    congratWindow = {
                        servId: services[key].servId,
                        servName: services[key].servName,
                        servPrice: services[key].servPrice,
                        haveSaleBalance: services[key].haveSaleBalance
                    };
                    userData[services[key].haveSaleBalance] = 0
                } else {
                    userData[services[key].haveSaleBalance]++
                }
            }
        }

        console.log(services)

        let data = {
            accessToken: getState().authReducer.accessToken,
            orderId: +getState().orderReducer.previewOrderData.id,
            userData,
            services,
            sale: congratWindow,
            workPointId: getState().authReducer.workPointId,
            sessionNumber: +getState().authReducer.sessionNumber
        };
        console.log(data)
        $.ajax({
            type: "POST",
            async: false,
            url: PATH__PLUS_SERVICES,
            dataType: "json",
            data: 'DATA=' + JSON.stringify(data),
            beforeSend: () => {
                console.log(userData);
            },
            success: data => {
                console.log(data);
                dispatch(getPreviewOrderData(null, true))
            },
            error: (error) => {
                console.log(error);
            }
        });
    }
}


export function setOrderStatusError(discription) {
    return async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: BASE_URL_API + '/order_in_progress/error_discription',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                orderId: +getState().orderReducer.previewOrderData.id,
                workPointId: +getState().authReducer.workPointId,
                discription
            }
        })
            .then(({ data }) => {
                console.log(data);
                dispatch(setStatusErrorDiscriptionWindow(0))
            })
            .catch(err => {
                return errorHandler(dispatch, setNotificationWindow, err, 'Ошибка завершения заказа!')
            });
    }
}


// const PATH__SET_ORDER_STATUS = BASE_URL_API + '/order_in_progress/set_order_status_get_gifts';
export function setOrderStatus(orderStatus, paymentType = 0) {
    console.log(paymentType)
    return async (dispatch, getState) => {
        await axios({
            method: 'put',
            url: BASE_URL_API + '/order_in_progress/' + getState().orderReducer.previewOrderData.id,
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                orderStatus: orderStatus,
                phone: +getState().orderReducer.previewOrderData.customer_phone,
                onCartoon: getState().orderInProgressReducer.onCartoon,
                age: getState().orderReducer.previewOrderData.customer.age,
                workPointId: +getState().authReducer.workPointId,
                paymentType
            }
        })
            .then(({ data }) => {
                console.log(data);
                dispatch(saveGiftData(data));
                dispatch(saveStatusOfOrderComplited(orderStatus));
                dispatch(setCustomerScreenWorkDirection(2));
            })
            .catch(err => {
                return errorHandler(dispatch, setNotificationWindow, err, 'Ошибка завершения заказа!')
            });
    }
}

function saveStatusOfOrderComplited(status) {
    return {
        type: ORDER__SAVE_SATATUS_OF_ORDER_COMPLITED,
        status
    }
}

function saveGiftData(giftData) {
    return {
        type: ORDER_SAVE_GIFT_DATA,
        giftData
    }
}

export function setSmsOption(optionSms, history) {
    return (dispatch, getState) => {
        let data = {
            accessToken: getState().authReducer.accessToken,
            orderId: +getState().orderReducer.previewOrderData.id,
            optionSms,
            phone: +getState().orderReducer.previewOrderData.customer_phone,
            workPointId: +getState().authReducer.workPointId
        };
        console.log(data);
        $.ajax({
            type: "POST",
            async: false,
            url: PATH__SET_SMS_OPTION,
            dataType: "json",
            data: 'DATA=' + JSON.stringify(data),
            success: e => {
                console.log(e);
                dispatch(orderClear());
                history.push('/turn/live')

            },
            error: (error) => {
                console.log(error);
            }
        });
    }
}

export function orderClear() {
    return {
        type: ORDER__OREDER_CLEAR
    }
}


export function setOrderStatusFromTurn(orderStatus) {
    return (dispatch, getState) => {

        let customerData = {
            phone: +getState().orderReducer.previewOrderData.customer_phone,
            balance: +getState().orderReducer.previewOrderData.customer.balance,
            creative_haircut_balance: +getState().orderReducer.previewOrderData.customer.creative_haircut_balance
        }
        let services = getState().orderReducer.previewOrderData.services;


        if (customerData.phone) {
            for (let i = 0; i < services.length; i++) {
                if (services[i].have_sale_balance) {
                    if (+customerData[services[i].have_sale_balance] === 0 && getState().orderReducer.previewOrderData.services_sale.length) {
                        customerData[services[i].have_sale_balance] = 2;
                    } else {
                        customerData[services[i].have_sale_balance]--
                    }
                }
            }
        }

        $.ajax({
            type: "POST",
            async: false,
            url: PATH__SET_ORDER_STATUS_FROM_TURN,
            dataType: "json",
            data: 'DATA=' + JSON.stringify({
                accessToken: getState().authReducer.accessToken,
                orderId: +getState().orderReducer.previewOrderData.id,
                orderStatus,
                customerData,
                sessionNumber: getState().authReducer.sessionNumber
            }),
            success: e => {
                console.log(e);
                dispatch(orderClear());

            },
            error: (error) => {
                console.log(error);
            }
        });
    }
}//309