import { combineReducers } from "redux";
import orderReducer from './order/orderReducer';
import authReducer from './auth/authReducer';
import masterReducer from './master/masterReducer';
import orderInProgressReducer from './orderInProgress/orderInProgressReducer';
import appPartsReducer from './appParts/appPartsReducer';

const rootReducer = combineReducers({
    orderReducer,
    authReducer,
    masterReducer,
    orderInProgressReducer,
    appPartsReducer
});

export default rootReducer;