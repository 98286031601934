import {
    SET__NOTIFICATION_WINDOW,
    REMOVE__ONE_NOTIFICATION_WINDOW,
    REMOVE__ONE_NOTIFICATION_WINDOW_ADD_CLASS_ANIMATION
} from "./action";

const initialState = {
    notificationWindows: localStorage.notificationWindows ? JSON.parse(localStorage.notificationWindows) : [],
}


const appPartsReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'AUTH__APP_CLEAR':
            return initialState

        case SET__NOTIFICATION_WINDOW: {

            let newNotificationWindows = [...state.notificationWindows];

            newNotificationWindows.push(action.data)
            if (newNotificationWindows.length > 3) {
                newNotificationWindows.shift();
            }

            localStorage.setItem('notificationWindows', JSON.stringify(newNotificationWindows));
            return {
                ...state,
                notificationWindows: newNotificationWindows
            }
        }


        case REMOVE__ONE_NOTIFICATION_WINDOW:

            let newNotificationWindows = [...state.notificationWindows];

            for (let i = 0; i < newNotificationWindows.length; i++) {
                if (newNotificationWindows[i].key == action.key) {
                    newNotificationWindows.splice(i, 1);
                    break;
                }
            }

            localStorage.setItem('notificationWindows', JSON.stringify(newNotificationWindows));
            return {
                ...state,
                notificationWindows: newNotificationWindows
            }


        case REMOVE__ONE_NOTIFICATION_WINDOW_ADD_CLASS_ANIMATION: {

            let newNotificationWindows = [...state.notificationWindows];

            for (let i = 0; i < newNotificationWindows.length; i++) {
                if (newNotificationWindows[i].key == action.key) {
                    newNotificationWindows[i].class = 'closing';
                    break;
                }

            }

            localStorage.setItem('notificationWindows', JSON.stringify(newNotificationWindows));
            return {
                ...state,
                notificationWindows: newNotificationWindows
            }

        }


        default:
            return state;
    }
}


export default appPartsReducer