import $ from 'jquery';
import { logout, saveTokens } from './redux/auth/action';
import { BASE_URL_AUTH } from './assets/js/baseUrl';

const PATH__REFRESH_TOKEN = BASE_URL_AUTH + '/master/refresh_token';


export function jwt({ dispatch, getState }) {

    return (next) => (action) => {

        if (typeof action === 'function') {

            if (getState().authReducer.login && getState().authReducer.accessToken ) { //

                console.log(`LAST SEC: ${( getState().authReducer.expiresIn * 1000 - Date.now()) /1000 }`)
                if (+Date.now() >= getState().authReducer.expiresIn * 1000 - 10000) {

                    //  make sure we are not already refreshing the token
                    if (!getState().authReducer.freshTokenPromise) {
                        return refreshToken(dispatch, getState().authReducer.refreshToken).then(() => next(action));
                    } else {
                        return getState().authReducer.freshTokenPromise.then(() => next(action));
                    }
                }
            } else {
                if (window.location.pathname !== '/') {
                    logout();
                }
            }


        }
        return next(action);
    }

};


export function refreshToken(dispatch, rf) {
    console.log(rf)
    var freshTokenPromise =  $.ajax({
        type: "POST",
        url: PATH__REFRESH_TOKEN,
        dataType: "json",
        data: 'DATA=' + JSON.stringify(
            { refreshToken: rf }
        ),
        success: t => {
            console.log(t);
            dispatch({
                            type: 'DONE_REFRESHING_TOKEN'
                        });
            
                        dispatch(saveTokens(t));
                        // console.log(t);
                        // return t ? Promise.resolve(t.accessToken) : Promise.reject({
                        //     message: 'could not refresh token'
                        // });
           

        },
        error: (error) => {
            console.log(error);
            logout();
            dispatch({
                type: 'DONE_REFRESHING_TOKEN'
            });
        }
    });
    
    // fetch(PATH__REFRESH_TOKEN, {
    //         method: 'POST',
    //         body: "DATA=" + JSON.stringify({ refreshToken: rf })
    //     }).then(response => response.json())
    //     .then(t => {
    //         dispatch({
    //             type: 'DONE_REFRESHING_TOKEN'
    //         });

    //         dispatch(saveTokens(t));
    //         console.log(t);
    //         return t ? Promise.resolve(t.accessToken) : Promise.reject({
    //             message: 'could not refresh token'
    //         });
    //     })
    //     .catch(e => {

    //         console.log('error refreshing token', e);
    //         //logout();
    //         dispatch({
    //             type: 'DONE_REFRESHING_TOKEN'
    //         });
    //         return Promise.reject(e);
    //     });



    dispatch({
        type: 'REFRESHING_TOKEN',

        // we want to keep track of token promise in the state so that we don't try to refresh
        // the token again while refreshing is in process
        freshTokenPromise
    });

    return freshTokenPromise;
}