export function displayPhone(arr, separator) {
    let str = "+";
    for (let i = 0; i < arr.length; i++) {
        switch (i) {
            case 3:
            case 5:
            case 8:
            case 10:
                str += separator;
                str += arr[i];
                break;

            default:
                str += arr[i];
                break;
        }
    }

    return str;
}

export function save(type, data) {
    return {
        type,
        data,
    }
}

export function errorHandler(dispatch, setNotificationWindow, err, msg) {
    console.log(err?.response);
    if (err?.response?.data) {
        dispatch(setNotificationWindow('error', `${msg} ${err?.response?.data}`));
    } else {
        dispatch(setNotificationWindow('error', `Ошибка соединения!`));
    }
   
    return new Promise((resolve, reject) => reject());
}