import {  useState, useEffect } from 'react';
import './OrderCompleted.scss';

import { connect } from 'react-redux';
import { setSmsOption, orderClear } from '../../redux/orderInProgress/action';

import { Redirect, withRouter } from "react-router-dom";

import { BASE_URL_AUDIO } from '../../assets/js/baseUrl';


function OrderCompleted(props) {

    useEffect(() => {
        document.title = "Завершение заказа | TOTO";
    }, [])

    const [smsOptions, setSmsOptions] = useState('');

    const selectSmsOption = (event) => {
        setSmsOptions(event.target.dataset.optionSms);
    }

    const completeOrder = (event) => {
        if (smsOptions !== '') {
            props.setSmsOption(smsOptions, props.history);
        }
        if (smsOptions === 0 || +props.previewOrderData.customer_phone === 0 || props.statusOrderComplited !== 3) {
            props.orderClear();
            props.history.push('/turn/live')
        }

    }

    useEffect(() => {
        if (props.statusOrderComplited === 3) {

            let playlist = [];
            if (Object.keys(props.previewOrderData).length !== 0) {
                if (props.previewOrderData.customer.age === 'до 10 лет' || +props.onCartoon) {
                    playlist.push(BASE_URL_AUDIO + 'adgootcut.mp3');
                } else {
                    playlist.push(BASE_URL_AUDIO + 'sale/' + (+props.previewOrderData.customer_phone === 0 ? 'wo' : '') + props.previewOrderData.all_sum + '.mp3');
                }
            }

            if (+props.previewOrderData.customer_phone !== 0) {
                for (let i = 0; i < props.previewOrderData.services.length; i++) {
                    if (props.previewOrderData.services[i].have_sale_balance) {
                        playlist.push(BASE_URL_AUDIO + 'bonus/' + props.previewOrderData.customer[props.previewOrderData.services[i].have_sale_balance] + '.mp3');
                    }
                }
                
            };

            playlist.push(BASE_URL_AUDIO + 'address/' + props.workPointId + '.mp3');


            let j = 0;
            const player = () => {
                if (j === playlist.length) {
                    return;
                }

                const audio = new Audio(playlist[j]);

                audio.play()
                audio.addEventListener('ended', () => {
                    j++
                    player()
                });
            }

            player()
        }
    }, [])


    if (!props.workplaceNumber) {
        return (<Redirect to="/workplace" />)
    }
    if (!props.sessionNumber) {
        return <Redirect to='/start_check' />
    }
    let smsOptionsArr = [
        { days: 14, text: '2 недели' },
        { days: 21, text: '3 недели' },
        { days: 31, text: '1 месяц' },
        { days: 45, text: '1.5 месяц' },
        { days: 61, text: '2 месяца' },
        { days: 0, text: 'без смс' }
    ];



    return (
        <div id="order_completed">

            <h1>Заказ <span>№ {props.previewOrderData.turn_num}</span> {props.statusOrderComplited === 3 ? 'выполнен' : 'отменен'} !</h1>

            {props.statusOrderComplited === 3 &&
                <>
                    <div class="customer_name">{props.previewOrderData.customer_name}</div>

                    <div class="gift_block">
                        <h2>Подарок - <span>{props.giftData.giftName}</span></h2>

                        <img src={props.giftData.giftSrc} alt="" />

                    </div>


                    {+props.previewOrderData.customer_phone !== 0 &&
                        <div class="sms_block">
                            <h2>Напомнить о стрижке</h2>
                            <div class="sms_options">
                                {smsOptionsArr.map((value) => {
                                    return (
                                        <label>
                                            <input
                                                type="radio"
                                                name='sms'
                                                onChange={selectSmsOption}
                                                key={value.days}
                                                data-option-sms={value.days}
                                            />
                                            <div class="option">{value.text}</div>
                                        </label>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </>
            }

            <button onClick={completeOrder}>Далее</button>

        </div >
    );
}


const mapStateToProps = state => {
    return {
        sessionNumber: state.authReducer.sessionNumber,
        workplaceNumber: state.authReducer.workplaceNumber,
        workPointId: state.authReducer.workPointId,

        previewOrderData: state.orderReducer.previewOrderData,
        
        statusOrderComplited: state.orderInProgressReducer.statusOrderComplited,
        onCartoon: state.orderInProgressReducer.onCartoon,
        giftData: state.orderInProgressReducer.giftData,
    }
}

const mapDispatchToProps = {
    setSmsOption,
    orderClear
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderCompleted))