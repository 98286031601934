const AddServicesStep = ({ services, setServices, servicesList }) => {

  const selectService = (e) => {
    e.stopPropagation()

    let servId = +e.target.value;
    let index = services.indexOf(servId);
    let newServices = [...services];

    if (index > -1) {
      newServices.splice(index, 1)
    } else {
      newServices.push(servId)
    }

    setServices(newServices);
  }

  return (
    <div class='serv_block'>
      {servicesList.map(({ id, name, price, serial_number }) => {
        if (serial_number == 13) return;

        return (
          <label>
            <input
              key={id}
              value={id}
              type='checkbox'
              className='serv_input'
              onChange={selectService}
              checked={services.includes(+id)}
            />
            <div className='serv'>
              <div className='serv_name'>{name}</div>
              <div className='serv_price'>{price} р</div>
            </div>
          </label>
        )
      })}
    </div>
  )
}

export default AddServicesStep