import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dialog, DialogContent, Typography, Button, Grid, Divider } from '@material-ui/core';

import { closeTurnItemPreview, checkPhone, addDataToOrder, getPreviewOrderData } from 'redux/order/action';
import { AddPhoneStep, AddGengerAgeStep, AddServicesStep } from 'pages/Turn/components/Steps';

import './TurnItemAddNewOrder.scss';

const TurnItemAddNewOrder = (props) => {

  useEffect(() => {
    document.title = 'Добавление данных в заказ | TOTO';
  }, [])

  const [phone, setPhone] = useState([3, 7, 5]);
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [services, setServices] = useState([]);

  const [step, setStep] = useState(0);
  const [prevStep, setPrevStep] = useState(0);
  const back = () => {
    step === 0 ? props.closeTurnItemPreview() :
    step === 1 ? setStep(0) : setStep(prevStep);
  }

  const next = () => {
    switch (step) {
      case 0:
        props.checkPhone(phone.join(''))
          .then(() => {
            setPrevStep(0);
            setGender(null);
            setAge(null);
            setStep(2)
          })
          .catch(() => {
            setPrevStep(0)
            setStep(1)
          })
        break;
      case 1:
        setPrevStep(1)
        setStep(2)
        break;

      case 2:
        setDisableNextButtonStatus(true);
        props.addDataToOrder(phone.join(''), gender, age, services)
        .then((data) => {
          props.getPreviewOrderData(props.previewOrderData.id);
        })
        .catch((error) => {
          setDisableNextButtonStatus(false);
          toast.error(error.toString())
        })
        break;
    }
  }

  const [disableNextButtonStatus, setDisableNextButtonStatus] = useState(true);
  const disableNextButton = () => {
    if (step === 0) phone.length === 12 ? setDisableNextButtonStatus(false) : setDisableNextButtonStatus(true);
    if (step === 1) gender && age ? setDisableNextButtonStatus(false) : setDisableNextButtonStatus(true);
    if (step === 2) setDisableNextButtonStatus(false);
  }

  useEffect(() => {
    disableNextButton()
  }, [phone, gender, age, step])

  const titles = ['Введите номер телефона', 'Выбирите пол и возраст', 'Выбирите услуги'];
  return (
    <Dialog open={true} className='add_new_order'>
      <DialogContent style={{ padding: '20px', }} >

        <Typography variant='h4' style={{ textAlign: 'center' }}>{titles[step]}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center' }}>Чек № {props?.previewOrderData?.turn_num}</Typography>

        <Divider style={{ margin: '10px 0' }} />

        {step === 0 && <AddPhoneStep phone={phone} setPhone={setPhone} setPrevStep={setPrevStep} setStep={setStep} />}
        {step === 1 && <AddGengerAgeStep gender={gender} setGender={setGender} age={age} setAge={setAge} />}
        {step === 2 && <AddServicesStep services={services} setServices={setServices} servicesList={props.servicesList} />}

        <Divider style={{ margin: '10px 0' }} />

        <Grid container spacing='2'>
          <Grid item xs='6'>
            <Button fullWidth variant='outlined' onClick={back} > Назад </Button>
          </Grid>
          <Grid item xs='6'>
            <Button disabled={disableNextButtonStatus} onClick={next} fullWidth variant='outlined'>Далее</Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({orderReducer, authReducer}) => {
  return {
    previewOrderData: orderReducer.previewOrderData,
    servicesList: authReducer.servicesList,
  }
}

const mapDispatchToProps = {
  closeTurnItemPreview,
  checkPhone,
  addDataToOrder,
  getPreviewOrderData,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TurnItemAddNewOrder))
