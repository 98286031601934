import axios from 'axios';
import { setNotificationWindow } from '../appParts/action';
import { save, errorHandler } from '../../assets/js/functions';

import $ from 'jquery';

import { BASE_URL_API } from '../../assets/js/baseUrl';

const PATH__GET_HISTORY_PAGE = BASE_URL_API + '/order_in_progress/history';





export const SET_ALERT_WINDOW = 'SET_ALERT_WINDOW';


export const SET_HISTORY_PAGE = 'SET_HISTORY_PAGE';
export const SET_HISTORY_ORDERS = 'SET_HISTORY_ORDERS';
export const ORDER_HYSTORY_SAVE_ALL_ITEMS = 'ORDER_HYSTORY_SAVE_ALL_ITEMS';




export function getHistoryPage() {
    return (dispatch, getState) => {

        $.ajax({
            type: "POST",
            async: false,
            url: PATH__GET_HISTORY_PAGE,
            dataType: "json",
            data: 'DATA=' + JSON.stringify({
                accessToken: getState().authReducer.accessToken,
                masterSession: getState().authReducer.sessionNumber,
                page: getState().masterReducer.historyPage
            }),
            success: data => {
                console.log('getHistoryPage');
                console.log(data);
                dispatch(saveHistoryAllItems(data.all))
                dispatch(setHistory(data.history));
                if(data.history.length === 0){
                    dispatch(saveHistoryPage(getState().masterReducer.historyPage - 1))

                }


            },
            error: (error) => {
                // dispatch(saveHistoryPage(getState().masterReducer.historyPage - 1))
                console.log('getHistoryPage');
                console.log(error);
            }
        });

    }

}

export function saveHistoryAllItems(allItems){
    return {
        type: ORDER_HYSTORY_SAVE_ALL_ITEMS,
        allItems
    }
}


export function saveHistoryPage(historyPage) {
    if (historyPage < 1) {
        historyPage = 1;
    }
    return {
        type: SET_HISTORY_PAGE,
        historyPage
    }
}
export function setHistory(historyOrders) {
    return {
        type: SET_HISTORY_ORDERS,
        historyOrders
    }
}


export const ORDER__SAVEFINISH_REPORT = 'ORDER__SAVEFINISH_REPORT';
export function setTimeForFinishSession(timeForFinish){
    return async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: BASE_URL_API + '/session/finish',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                masterSession: getState().authReducer.sessionNumber,
                timeForFinish,
                workPointId: getState().authReducer.workPointId
            }
        })
            .then(({ data }) => {
                console.log(data)
                dispatch(save(ORDER__SAVEFINISH_REPORT, data))
            })
            .catch(err => {
                return errorHandler(dispatch, setNotificationWindow, err, 'Ошибка получения отчета!')
            });
    } 
}


export function closeSession(tip) {
    return async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: BASE_URL_API + '/session/close',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                masterSession: getState().authReducer.sessionNumber,
                tip,
            }
        })
            .then(({ data }) => {
                console.log(data)
                // dispatch(appClear())
            })
            .catch(err => {
                return errorHandler(dispatch, setNotificationWindow, err, 'Ошибка получения отчета!')
            });
    }
}

export function appClear(){
    return {
        type: 'AUTH__APP_CLEAR'
    }
}