import { Button, TextField} from '@material-ui/core';
import { displayPhone } from 'assets/js/functions';

const AddPhoneStep = ({phone, setPhone, setStep, setPrevStep }) => {

  const changePhone = (event) => {
    if (event.nativeEvent.inputType === 'deleteContentBackward' && phone.length) {
      let arr = [...phone];
      arr.pop();
      setPhone(arr);
      return;
    }

    if (phone.length === 12) return;

    if (!isNaN(event.nativeEvent.data)) {
      let arr = [...phone];
      arr.push(+event.nativeEvent.data);
      setPhone(arr)
    }
  }

  return (
    <>
      <TextField type='text' onChange={changePhone} value={displayPhone(phone, '-')} fullWidth class="input_phone" style={{ margin: '30px 0' }} />

      <Button
        onClick={() => {
          setPrevStep(0)
          setStep(2)
          setPhone([0])
        }}
        fullWidth
        variant='outlined'
      >
        Продолжить без телефона
      </Button>
    </>
  )
}

export default AddPhoneStep;
