import { useEffect } from 'react';
import './CustomerHistory.scss';
import { connect } from 'react-redux';

import { saveCustomerHistoryPage, getCustomerHistory, setOpenCustomerHistory, } from '../../redux/order/action';
import { displayPhone } from '../../assets/js/functions';


function CustomerHistory(props) {

    useEffect(() => {
        props.getCustomerHistory() //getCustomerHistoryPage
    }, [])

    useEffect(() => {
        props.getCustomerHistory()
    }, [props.historyCustomerPage])


    const plusPage = () => {
        if (props.historyCustomerPage === Math.ceil(props.historyCustomerOrders?.totalItems / props.historyCustomerOrdersPerPage)) {
            return;
        }
        props.saveCustomerHistoryPage(props.historyCustomerPage + 1)
        document.querySelector('#customer_history').scrollIntoView(10000); // For Safari
    }

    const minusPage = () => {
        if (props.historyCustomerPage === 1) {
            return;
        }
        props.saveCustomerHistoryPage(props.historyCustomerPage - 1)
        document.querySelector('#customer_history').scrollIntoView(10000); // For Safari
    }

    if (!props.openCustomerHistory) {
        return ''
    }


    return (
        <div id="customer_history">
            <h3>История клиента <br /> {displayPhone(props.previewOrderData.customer_phone.split(''), '-')}</h3>

            <div className="table_wrapp">
                <table>
                    <thead>
                        <tr>
                            <th>Номер заказа</th>
                            <th>Услуги</th>
                            <th>Дата заказа</th>
                            <th>Сумма заказа</th>
                            <th>Статус заказа</th>
                        </tr>
                    </thead>
                    <tbody>

                        {props.historyCustomerOrders.items.map(value => {
                            return (
                                <tr style={value.status_id !== 3 ? { backgroundColor: 'rgba(179, 1, 1, .2)' } : {}}>
                                    <td>{value.id}</td>
                                    <td dangerouslySetInnerHTML={{ __html: value.services }}></td>
                                    <td>{value.date_time}</td>
                                    <td>{value.sum}</td>
                                    <td>{value.status}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>

            </div>

            <nav>
                <button onClick={minusPage} className="to_back">{'<'} </button>
                <button onClick={() => props.setOpenCustomerHistory(0)} className="back">НАЗАД</button>
                <button onClick={plusPage} className="to_next"> {'>'}</button>
            </nav>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        previewOrderData: state.orderReducer.previewOrderData,
        openCustomerHistory: state.orderReducer.openCustomerHistory,
        historyCustomerOrders: state.orderReducer.historyCustomerOrders,
        historyCustomerOrdersPerPage: state.orderReducer.historyCustomerOrdersPerPage,
        historyCustomerPage: state.orderReducer.historyCustomerPage
    }
}

const mapDispatchToProps = {
    saveCustomerHistoryPage,
    getCustomerHistory,
    setOpenCustomerHistory
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomerHistory)//186