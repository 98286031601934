import axios from 'axios';
import { setNotificationWindow } from '../appParts/action';
import { save, errorHandler } from '../../assets/js/functions';

import $ from 'jquery';

import { BASE_URL_AUTH, BASE_URL_API } from '../../assets/js/baseUrl';










export const ORDER__SET_OPEN_CUSTOMER_HISTORY = 'ORDER__SET_OPEN_CUSTOMER_HISTORY';


export const ORDER__SAVE_TURN = 'ORDER__SAVE_TURN';
export const ORDER__SAVE_APPOINTMENT = 'ORDER__SAVE_APPOINTMENT';
export const ORDER__SAVE_CURRENT_ORDERS = 'ORDER__SAVE_CURRENT_ORDERS';
export function getTurn() {
    return async (dispatch, getState) => {
        await axios({
            method: 'get',
            url: BASE_URL_API + '/turn',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            params: {
                workPointId: getState().authReducer.workPointId
            }
        })
            .then(({ data }) => {
                console.log(data)
                dispatch(save(ORDER__SAVE_TURN, data.turn));
                dispatch(save(ORDER__SAVE_APPOINTMENT, data.appointment))
                dispatch(save(ORDER__SAVE_CURRENT_ORDERS, data.currentOrders))
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка получения очереди!')
            });
    }
}


export function addName(newName) {
    return async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: BASE_URL_API + '/turn/name',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                phone: getState().orderReducer.previewOrderData.customer_phone,
                name: newName
            }
        })
            .then(({ data }) => {
                // console.log(data)
                let id = +getState().orderReducer.previewOrderId;
                dispatch(getPreviewOrderData(id, true))
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка добавления имени!')
            });
    }
}


export const ORDER__SAVE_PREVIEW_OREDER_DATA = 'ORDER__SAVE_PREVIEW_OREDER_DATA';
export const ORDER__OPEN_TURN_ITEM_PREVIEW = 'ORDER__OPEN_TURN_ITEM_PREVIEW';
export function getPreviewOrderData(orderId, status = false) {
    return async (dispatch, getState) => {
        if (!orderId) {
            orderId = +getState().orderReducer.previewOrderData.id;
        }

        await axios({
            method: 'get',
            url: BASE_URL_API + '/turn/' + orderId,
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            params: {
                status
            }
        })
            .then(({ data }) => {
                console.log(data)
                if (!status) {
                    dispatch(save(ORDER__SAVE_PREVIEW_ORDER_ID, orderId));
                    dispatch(save(ORDER__OPEN_TURN_ITEM_PREVIEW, 1))
                }
                dispatch(save(ORDER__SAVE_PREVIEW_OREDER_DATA, data));

            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка получения данных заказа!')
            });
    }
}


export const ORDER__SAVE_PREVIEW_ORDER_ID = 'ORDER__SAVE_PREVIEW_ORDER_ID';
export function closeTurnItemPreview() {
    return async (dispatch, getState) => {
        await axios({
            method: 'put',
            url: BASE_URL_API + '/turn/' + getState().orderReducer.previewOrderId,
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                status: 1
            }
        })
            .then(({ data }) => {
                console.log(data);
                dispatch(save(ORDER__OPEN_TURN_ITEM_PREVIEW, 0));
                dispatch(save(ORDER__SAVE_PREVIEW_ORDER_ID, 0));
                dispatch(save(ORDER__SAVE_PREVIEW_OREDER_DATA, []));
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка закрытия предпросмотра заказа!')
            });
    }
}


export function addPhone(phone, gender, age, name) {
    return async (dispatch, getState) => {
        await axios({
            method: 'put',
            url: BASE_URL_API + '/turn/' + getState().orderReducer.previewOrderId + '/phone',
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                orderId: +getState().orderReducer.previewOrderId,
                phone,
                gender,
                age,
                name
            }
        })
            .then(({ data }) => {
                console.log(data);
                let id = +getState().orderReducer.previewOrderId;
                dispatch(getPreviewOrderData(id, true))
                dispatch(setOpenAddPhone(0))
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка закрытия предпросмотра заказа!')
            });
    }
}


export const ORDER__SET_OPEN_ADD_PHONE = 'ORDER__SET_OPEN_ADD_PHONE';
export function setOpenAddPhone(status) {
    return {
        type: ORDER__SET_OPEN_ADD_PHONE,
        status
    }
}


export const ORDER__SET_STATUS_ERROR_DISCRPTION_WINDOW = 'ORDER__SET_STATUS_ERROR_DISCRPTION_WINDOW';
export function setStatusErrorDiscriptionWindow(status) {
    return {
        type: ORDER__SET_STATUS_ERROR_DISCRPTION_WINDOW,
        status
    }
}


export function takeOrder(customerName) {
    return async (dispatch, getState) => {
        await axios({
            method: 'put',
            url: BASE_URL_API + '/turn/' + getState().orderReducer.previewOrderId,
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: {
                status: 2,
                sessionNumber: +getState().authReducer.sessionNumber,
                customerName
            }
        })
            .then(({ data }) => {
                console.log(data);
                getPreviewOrderData(getState().orderReducer.previewOrderId, true)
                dispatch(save(ORDER__OPEN_TURN_ITEM_PREVIEW, 0))
                dispatch(setCustomerScreenWorkDirection(1))

            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка взятия заказа!')
            });
    }
}


//work directions 1 start video 2 stop video 3 catoon 3-4;  4 catoon 5-6; 5 catoon 7-10; 6 off cartoon 7 stop any money 8 stop any CUSTOMER
export function setCustomerScreenWorkDirection(workDirection) {
    return (dispatch, getState) => {
        $.ajax({
            type: "POST",
            async: false,
            url: BASE_URL_API + '/order_in_progress/set_customer_screen_work_direction',
            dataType: "json",
            data: 'DATA=' + JSON.stringify({
                accessToken: getState().authReducer.accessToken,
                workDirection
            }),
            success: e => {
                console.log(e);
            },
            error: (error) => {
                console.log(error);
            }
        });
    }
}


export function setOpenCustomerHistory(status) {
    return {
        type: ORDER__SET_OPEN_CUSTOMER_HISTORY,
        status

    }
}

//history part
// const PATH__GET_CUSTOMER_HISTORY_PAGE = BASE_URL_API + '/master/order_in_progress/get_customer_history_page';
export const SET_CUSTOMER_HISTORY_ORDERS = 'SET_CUSTOMER_HISTORY_ORDERS';
export function getCustomerHistory() {
    return async (dispatch, getState) => {
        await axios({
            method: 'get',
            url: BASE_URL_API + '/turn/customer_history/' + getState().orderReducer.historyCustomerPage,
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            params: {
                phone: +getState().orderReducer.previewOrderData.customer_phone,
            }
        })
            .then(({ data }) => {
                dispatch(save(SET_CUSTOMER_HISTORY_ORDERS, data));
                if (data.items.length === 0 && getState().orderReducer.historyCustomerPage !== 1) {
                    dispatch(saveCustomerHistoryPage(getState().orderReducer.historyCustomerPage - 1))
                }
            })
            .catch(err => {
                errorHandler(dispatch, setNotificationWindow, err, 'Ошибка получения истории заказов!')
            });
    }
}


export const SET_CUSTOMER_HISTORY_PAGE = 'SET_CUSTOMER_HISTORY_PAGE';
export function saveCustomerHistoryPage(data) {
    return {
        type: SET_CUSTOMER_HISTORY_PAGE,
        data
    }
}

// export const SET_CUSTOMER_HISTORY_ORDERS = 'SET_CUSTOMER_HISTORY_ORDERS';
export function checkPhone(phone) {
    return async (dispatch, getState) => {
        await axios({
            method: 'get',
            url: BASE_URL_API + '/turn/customer/' + phone,
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
        })
        // .then(({ data }) => {
        //     dispatch(save(SET_CUSTOMER_HISTORY_ORDERS, data));
        //     if (data.items.length === 0 && getState().orderReducer.historyCustomerPage !== 1) {
        //         dispatch(saveCustomerHistoryPage(getState().orderReducer.historyCustomerPage - 1))
        //     }
        // })
        // .catch(err => {
        //     errorHandler(dispatch, setNotificationWindow, err, 'Ошибка получения истории заказов!')
        // });
    }
}

export function addDataToOrder(phone, gender, age, services) {
    return async (dispatch, getState) => {
        await axios({
            method: 'post',
            url: BASE_URL_API + `/turn/${getState().orderReducer.previewOrderData.id}/add`,
            headers: {
                'Authorization': 'Bearer ' + getState().authReducer.accessToken
            },
            data: { phone, gender, age, services, workPointId: +getState().authReducer.workPointId }
        })
        // .then(({ data }) => {
        //     dispatch(save(SET_CUSTOMER_HISTORY_ORDERS, data));
        //     if (data.items.length === 0 && getState().orderReducer.historyCustomerPage !== 1) {
        //         dispatch(saveCustomerHistoryPage(getState().orderReducer.historyCustomerPage - 1))
        //     }
        // })
        // .catch(err => {
        //     errorHandler(dispatch, setNotificationWindow, err, 'Ошибка получения истории заказов!')
        // });
    }
}