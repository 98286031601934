import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import './CheckListStart.scss';

import { checkStartSession, startSession } from '../../redux/auth/action';

import Button from '../../components/Button/Button';
import CheckListItem from '../../components/CheckListItem/CheckListItem';


function CheckListStart(props) {

    useEffect(() => {
        document.title = "КЛН";
        props.checkStartSession();
    }, [])

    let timeForStart = +new Date();
    let items = [
        'Чистота рабочего шкафа и кресла',
        'Чистота зеркала',
        'Чистота пола',
        'Пополнены ли подарки',
        'Пустое мусорное ведро',
        'Чистый пылесос',
    ];


    const goToTurn = (e) => {
        e.preventDefault();

        if (document.querySelectorAll('input:checked').length === items.length) {
            props.startSession(Math.round((+new Date() - timeForStart) / 1000))
        }
    }


    if (!props.workplaceNumber) {
        return (<Redirect to="/workplace" />)
    }
    if (props.sessionNumber) {
        return (<Redirect to="/turn/live" />)
    }

    return (
        <div className="check_list_start">
            <h1>КЛН</h1>
            <form onSubmit={goToTurn} action="">
                {items.map(item => <CheckListItem key={item}>{item}</CheckListItem>)}
                <div className="btns">
                    <Button>{'ДАЛЕЕ >'}</Button>
                </div>
            </form>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        workplaceNumber: state.authReducer.workplaceNumber,
        sessionNumber: state.authReducer.sessionNumber,
    }
}

const mapDispatchToProps = {
    checkStartSession,
    startSession
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckListStart)