import { useState } from 'react';
import './AddPhoneWindow.scss';
import { connect } from 'react-redux';


import { addPhone, setOpenAddPhone } from '../../redux/order/action';
import { displayPhone } from '../../assets/js/functions';

function AddPhoneWindow(props) {

    const [phone, setPhone] = useState([3, 7, 5]);
    const [name, setName] = useState('');
    const [gender, setGender] = useState(null);
    const [age, setAge] = useState(null);

    const changePhone = (event) => {
        console.log(event)
        if (event.nativeEvent.inputType === 'deleteContentBackward' && phone.length) {

            let arr = [...phone];
            arr.pop();
            console.log(arr)
            setPhone(arr)
            return;
        }

        if (phone.length === 12) {
            return;
        }
        if (!isNaN(event.nativeEvent.data)) {
            let arr = [...phone];
            arr.push(+event.nativeEvent.data);
            setPhone(arr)
        }
    }

    const changeName = (event) => {
        setName(event.target.value)
    }

    const onChangeGenderAge = (event) => {
        let item = event.target;
        if (item.name === 'gender') {
            setGender(item.value)
        }
        if (item.name === 'age') {
            setAge(item.value)
        }
    }

    const addPhone = (event) => {
        event.preventDefault();

        if (phone.length === 12 && gender && age) {
            props.addPhone(phone.join(''), gender, age, name)
        }
    }

    if (!props.openAddPhone) {
        return ''
    }


    return (
        <form id="add_phone_gender_age" onSubmit={addPhone}>

            <h3>Добавление номера в заказ</h3>

            <input type='text' onChange={changePhone} value={displayPhone(phone, '-')} class="input_phone" />

            <h3>Введите имя клиента</h3>

            <input type='text' onChange={changeName} value={name} class="input_name" placeholder="Введите имя клиента" />

            <div className="gender">
                <h3>Выберите пол</h3>
                <div className="gender_boxes">
                    <label>
                        <input type="radio" name="gender" value='мужской' id='male' onChange={onChangeGenderAge} />
                        <div className="box">
                            Мужской
                            </div>
                    </label>

                    <label>
                        <input type="radio" name="gender" value='женский' id='female' onChange={onChangeGenderAge} />
                        <div className="box">
                            Женский
                            </div>
                    </label>
                </div>
            </div>

            <h3>Выберите возраст</h3>
            <div className="age">
                {['до 10 лет', '11-20 лет', "21-30 лет", "31-40 лет", "41-50 лет", "51-60 лет", "60+ лет"].map((value, index) => {
                    return (
                        <label key={index}>
                            <input type="radio" name="age" value={value} onChange={onChangeGenderAge} />
                            <div className="agebricks">{value}</div>
                        </label>
                    )
                })}
            </div>


            <div className="bnt_block">
                <button onClick={() => props.setOpenAddPhone(0)} className="to_back">{"<"} НАЗАД</button>
                <button type='submit' style={phone.length === 12 && gender && age ? { color: '#b30101' } : { color: '#c3c3c3' }} className="phone_next">ПРОДОЛЖИТЬ</button>
            </div>

        </form>
    )
}

const mapStateToProps = state => {
    return {
        openAddPhone: state.orderReducer.openAddPhone
    }
}

const mapDispatchToProps = {
    addPhone,
    setOpenAddPhone
}


export default connect(mapStateToProps, mapDispatchToProps)(AddPhoneWindow)