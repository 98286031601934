import { Divider } from '@material-ui/core';

const AddGengerAgeStep = ({ gender, setGender, age, setAge }) => {

  const onChangeGenderAge = (event) => {
    let item = event.target;
    
    if (item.name === 'gender') {
      setGender(item.value);
    }
    if (item.name === 'age') {
      setAge(item.value);
    }
  }

  return (
    <>
      <div className='gender' style={{ margin: '25px 0 0 0' }}>
        <div className='gender_boxes'>
          <label>
            <input type='radio' name='gender' value='мужской' id='male' onChange={onChangeGenderAge} checked={gender === 'мужской'} />
            <div className='box'>
              Мужской
            </div>
          </label>

          <label>
            <input type='radio' name='gender' value='женский' id='female' onChange={onChangeGenderAge} checked={gender === 'женский'} />
            <div className='box'>
              Женский
            </div>
          </label>
        </div>
      </div>

      <Divider style={{ margin: '10px 0 25px' }} />

      <div className='age'>
        {['до 10 лет', '11-20 лет', '21-30 лет', '31-40 лет', '41-50 лет', '51-60 лет', '60+ лет'].map((value, index) => {
          return (
            <label key={index}>
              <input type='radio' name='age' value={value} onChange={onChangeGenderAge} checked={age === value} />
              <div className='agebricks'>{value}</div>
            </label>
          )
        })}
      </div>
    </>
  )
}

export default AddGengerAgeStep;
